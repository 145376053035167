import React from 'react';

const WorkflowEmpty = () => {
	return (
		<div className='workflow-card'>
			<p className='gray800-14 text-center'>Nothing yet. Add a new workflow to assign them to data access request applications.</p>
		</div>
	);
};

export default WorkflowEmpty;
